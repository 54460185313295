* {
    box-sizing: border-box;
}

.container {
    text-align: center;
}

.menu-item {
    user-select: none;
    cursor: pointer;
    color: #185bd7;
    font-size: 16px;
    margin: 4px 8px;
    text-decoration: none;
}
.menu-item:visited {
    color: #185bd7;
}
.menu-item-wrapper:focus {
    outline: none;
}

.menu-item.active {
    color: #1756d6;
    font-size: 16px;
    margin: 4px 7px;
    text-decoration: underline;
    font-weight: bold;
}

.menu-item.active:visited {
    color: #1756d6;
}

.scroll-menu-arrow {
    cursor: pointer;
}

.scroll-menu-arrow--disabled {
    visibility: hidden;
}

.arrow-prev {
    margin: 0px 0px 0px 12px
}

.arrow-next {
    margin: 0px 12px 0px 0px
}
