.App {
    background-color: #fff;
    font-size: 15px;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #444444;
    height: 100vh;
}

.App-logo {
    height: 40vmin;
}

.App-header-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
}

.App-header div {
    width: 93% !important;
}

a:link {
    color: #1970b7;
}

.App-header h2 {
    font-size: 1.5rem;
    margin-bottom: 0;
}

.App-header h3 {
    font-size: 0.85rem;
    margin-top: 0;
    margin-bottom: 0;
}

.App-content {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.App-content-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.App-content-title h1 {
    color: #1970b7;
}

.App-content-title h2 {
    font-size: 1.1rem;
    margin-top: 0;
    font-weight: 400;
    text-align: center;
}

.App-files-root {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.App-files-tabs-and-header {
    width: 80%;
}

.App-files-pdf-view {
    width: 100%;
}

.App-terms {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.App-terms-content {
    display: flex;
    width: 90%;
    justify-content: center;
    font-weight: 500;
    align-items: flex-start;
    flex-direction: column;
    font-size: 18px;
}

.App-info {
    margin-top: 10px !important;
    width: 90% !important;
}

.App-info-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.App-info-subtitle {
    text-align: center;
}

.App-info-subtitle h2 {
    font-size: 1.25rem;
}

.App-info-text {
    text-align: center;
    font-size: 14px;
}

.App-footer {
    margin-left: auto;
    margin-right: auto;
    margin-top: 75px;
    line-height: 48px;
    display: flex;
    position: relative;
    max-width: 90%;
    justify-content: space-between;
    font-size: 14px;
}

.App-link {
    color: #09d3ac;
}

#inner {
    overflow: hidden;
}

.child {
    float: left;
    padding: 10px;
    height: 700px;
}

#inner1 {
    overflow: hidden;
    text-align: center;
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
}

.child-dropdown {
    float: left;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 5px;
    font-weight: bold;
}

.child-text {
    float: left;
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #777777;
    font-size: 13px;
    margin-top: 12px;
}

.radio-group {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #777777;
    font-size: 13px;
    margin-left: 10px;
}

.header-recognition {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #777777;
    font-size: 14px;
    margin-top: 15px;
    font-weight: bolder;
}

#dropdown-item {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #777777;
    font-size: 14px;
}

#dropdown-header {
    font-family: "Raleway", Helvetica, Arial, sans-serif;
    color: #777777;
    font-size: 14px;
}

.inlineList {
    columns: 3;
    -webkit-columns: 3;
    -moz-columns: 3;
}

.filePicker {
    background: #f3f5f5 !important;
    border: 1px solid #C7C7C7 !important;
    font-size: 18px;
}

.cookies-toolbar {
    align-items: center !important;
}

@media (max-width: 500px) {
    .cookies-text {
        flex-direction: column;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .cookies-button {
        margin-top: 5px !important;
    }

    .cookies-button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
}

.App-dropzone {
    width: 90%;
}

/* FROM file dropzone.min.css */
@-webkit-keyframes passing-through {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30%, 70% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        -moz-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
        -o-transform: translateY(-40px);
        transform: translateY(-40px)
    }
}

@-moz-keyframes passing-through {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30%, 70% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        -moz-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
        -o-transform: translateY(-40px);
        transform: translateY(-40px)
    }
}

@keyframes passing-through {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30%, 70% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
    100% {
        opacity: 0;
        -webkit-transform: translateY(-40px);
        -moz-transform: translateY(-40px);
        -ms-transform: translateY(-40px);
        -o-transform: translateY(-40px);
        transform: translateY(-40px)
    }
}

@-webkit-keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
}

@-moz-keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
}

@keyframes slide-in {
    0% {
        opacity: 0;
        -webkit-transform: translateY(40px);
        -moz-transform: translateY(40px);
        -ms-transform: translateY(40px);
        -o-transform: translateY(40px);
        transform: translateY(40px)
    }
    30% {
        opacity: 1;
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        -o-transform: translateY(0px);
        transform: translateY(0px)
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    10% {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1)
    }
    20% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@-moz-keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    10% {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1)
    }
    20% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
    10% {
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
        -o-transform: scale(1.1);
        transform: scale(1.1)
    }
    20% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1)
    }
}

.dropzone, .dropzone * {
    box-sizing: border-box
}

.dropzone {
    min-height: 150px;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: white;
    padding: 20px 20px
}

.dropzone.dz-clickable {
    cursor: pointer
}

.dropzone.dz-clickable * {
    cursor: default
}

.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
    cursor: pointer
}

.dropzone.dz-started .dz-message {
    display: none
}

.dropzone.dz-drag-hover {
    border-style: solid
}

.dropzone.dz-drag-hover .dz-message {
    opacity: 0.5
}

.dropzone .dz-message {
    text-align: center;
    margin: 2em 0
}

.dropzone .dz-message .dz-button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit
}

.dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px
}

.dropzone .dz-preview:hover {
    z-index: 1000
}

.dropzone .dz-preview:hover .dz-details {
    opacity: 1
}

.dropzone .dz-preview.dz-file-preview .dz-image {
    border-radius: 20px;
    background: #999;
    background: linear-gradient(to bottom, #eee, #ddd)
}

.dropzone .dz-preview.dz-file-preview .dz-details {
    opacity: 1
}

.dropzone .dz-preview.dz-image-preview {
    background: white
}

.dropzone .dz-preview.dz-image-preview .dz-details {
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    -ms-transition: opacity 0.2s linear;
    -o-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear
}

.dropzone .dz-preview .dz-remove {
    font-size: 14px;
    text-align: center;
    display: block;
    cursor: pointer;
    border: none
}

.dropzone .dz-preview .dz-remove:hover {
    text-decoration: underline
}

.dropzone .dz-preview:hover .dz-details {
    opacity: 1
}

.dropzone .dz-preview .dz-details {
    z-index: 20;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    font-size: 13px;
    min-width: 100%;
    max-width: 100%;
    padding: 2em 1em;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    line-height: 150%
}

.dropzone .dz-preview .dz-details .dz-size {
    margin-bottom: 1em;
    font-size: 16px
}

.dropzone .dz-preview .dz-details .dz-filename {
    white-space: nowrap
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
    border: 1px solid rgba(200, 200, 200, 0.8);
    background-color: rgba(255, 255, 255, 0.8)
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
    overflow: hidden;
    text-overflow: ellipsis
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
    border: 1px solid transparent
}

.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
    background-color: rgba(255, 255, 255, 0.4);
    padding: 0 0.4em;
    border-radius: 3px
}

.dropzone .dz-preview:hover .dz-image img {
    -webkit-transform: scale(1.05, 1.05);
    -moz-transform: scale(1.05, 1.05);
    -ms-transform: scale(1.05, 1.05);
    -o-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    -webkit-filter: blur(8px);
    filter: blur(8px)
}

.dropzone .dz-preview .dz-image {
    border-radius: 20px;
    overflow: hidden;
    width: 120px;
    height: 120px;
    position: relative;
    display: block;
    z-index: 10
}

.dropzone .dz-preview .dz-image img {
    display: block
}

.dropzone .dz-preview.dz-success .dz-success-mark {
    -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1)
}

.dropzone .dz-preview.dz-error .dz-error-mark {
    opacity: 1;
    -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1)
}

.dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
    pointer-events: none;
    opacity: 0;
    z-index: 500;
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -27px;
    margin-top: -27px
}

.dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
    display: block;
    width: 54px;
    height: 54px
}

.dropzone .dz-preview.dz-processing .dz-progress {
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear
}

.dropzone .dz-preview.dz-complete .dz-progress {
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-in;
    -moz-transition: opacity 0.4s ease-in;
    -ms-transition: opacity 0.4s ease-in;
    -o-transition: opacity 0.4s ease-in;
    transition: opacity 0.4s ease-in
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
    -webkit-animation: pulse 6s ease infinite;
    -moz-animation: pulse 6s ease infinite;
    -ms-animation: pulse 6s ease infinite;
    -o-animation: pulse 6s ease infinite;
    animation: pulse 6s ease infinite
}

.dropzone .dz-preview .dz-progress {
    opacity: 1;
    z-index: 1000;
    pointer-events: none;
    position: absolute;
    height: 16px;
    left: 50%;
    top: 50%;
    margin-top: -8px;
    width: 80px;
    margin-left: -40px;
    background: rgba(255, 255, 255, 0.9);
    -webkit-transform: scale(1);
    border-radius: 8px;
    overflow: hidden
}

.dropzone .dz-preview .dz-progress .dz-upload {
    background: #333;
    background: linear-gradient(to bottom, #666, #444);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 0;
    -webkit-transition: width 300ms ease-in-out;
    -moz-transition: width 300ms ease-in-out;
    -ms-transition: width 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out
}

.dropzone .dz-preview.dz-error .dz-error-message {
    display: block
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
    opacity: 1;
    pointer-events: auto
}

.dropzone .dz-preview .dz-error-message {
    pointer-events: none;
    z-index: 1000;
    position: absolute;
    display: block;
    display: none;
    opacity: 0;
    -webkit-transition: opacity 0.3s ease;
    -moz-transition: opacity 0.3s ease;
    -ms-transition: opacity 0.3s ease;
    -o-transition: opacity 0.3s ease;
    transition: opacity 0.3s ease;
    border-radius: 8px;
    font-size: 13px;
    top: 130px;
    left: -10px;
    width: 140px;
    background: #be2626;
    background: linear-gradient(to bottom, #be2626, #a92222);
    padding: 0.5em 1.2em;
    color: white
}

.dropzone .dz-preview .dz-error-message:after {
    content: '';
    position: absolute;
    top: -6px;
    left: 64px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #be2626
}
