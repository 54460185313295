//Extensions

/*=============== DropZone ================*/
.dropzone-wrapper{
   .dropzone{
      .dz-preview.dz-image-preview{
         background:transparent !important;
      }
      .dz-error-message,.dz-preview.dz-error .dz-error-mark{
         display:none !important;
      }
      .dz-remove{
         padding:0.25rem;
         background-color:red;
         color:white;
         text-decoration: none;
         border-radius:0.3125rem;
         margin-top:1rem;
         &:hover{
            text-decoration: none;
         }
      }
   }
}
